.myBoxOnTop {
    z-index: 3;
    position: relative;
}

.myBoxOnBottom {
    z-index: 2;
    position: absolute;
}

.element::-webkit-scrollbar {
    display: none;
  }

/* * {
    background: #000 !important;
    color: #0f0 !important;
    outline: solid #f00 1px !important;
} */

html {
    scroll-behavior: smooth;
  }


 @keyframes fadeInOut {
    0% { opacity: 0; }
    40% { opacity: 1; }
    50% { opacity: 1; }
    100% { opacity: 0; }
 }

 @keyframes followPath {
    100% { offset-distance: 100%; }
 }

.dot {
offset-path: path('M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1 c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z');
position: absolute;
transform: scale(3);

}

.dot {
    display: inline-block;
    width: 0.4rem;
    height: 0.4rem;
    background: #8D63FF;
    border-radius: 50%;
    opacity: 0;
    animation: pulse 1800ms infinite ease-in-out;
    transform: scale(3);
}
.dot:nth-of-type(1) {
    offset-distance: 10%;
    animation-delay: 180ms;
}
.dot:nth-of-type(2) {
    offset-distance: 20%;
    animation-delay: 360ms;
}
.dot:nth-of-type(3) {
    offset-distance: 30%;
    animation-delay: 540ms;
}
.dot:nth-of-type(4) {
    offset-distance: 40%;
    animation-delay: 720ms;
}
.dot:nth-of-type(5) {
    offset-distance: 50%;
    animation-delay: 900ms;
}
.dot:nth-of-type(6) {
    offset-distance: 60%;
    animation-delay: 1080ms;
}
.dot:nth-of-type(7) {
    offset-distance: 70%;
    animation-delay: 1260ms;
}
.dot:nth-of-type(8) {
    offset-distance: 80%;
    animation-delay: 1440ms;
}
.dot:nth-of-type(9) {
    offset-distance: 90%;
    animation-delay: 1620ms;
}
.dot:nth-of-type(10) {
    offset-distance: 0%;
    animation-delay: 0ms;
}
.dot:nth-of-type(11) {
    offset-distance: 5%;
    animation-delay: 90ms;
}
.dot:nth-of-type(12) {
    offset-distance: 15%;
    animation-delay: 270ms;
}
.dot:nth-of-type(13) {
    offset-distance: 25%;
    animation-delay: 450ms;
}
.dot:nth-of-type(14) {
    offset-distance: 35%;
    animation-delay: 630ms;
}
.dot:nth-of-type(15) {
    offset-distance: 45%;
    animation-delay: 810ms;
}
.dot:nth-of-type(16) {
    offset-distance: 55%;
    animation-delay: 990ms;
}
.dot:nth-of-type(17) {
    offset-distance: 65%;
    animation-delay: 1170ms;
}
.dot:nth-of-type(18) {
    offset-distance: 75%;
    animation-delay: 1350ms;
}
.dot:nth-of-type(19) {
    offset-distance: 85%;
    animation-delay: 1530ms;
}
.dot:nth-of-type(20) {
    offset-distance: 95%;
    animation-delay: 1710ms;
}

@keyframes pathy {
    100% {
        offset-distance: 100%;
   }
}

@keyframes pulse {
    20% {
        opacity: 1;
   }
    40% {
        opacity: 0;
   }
}

.no-motionpath .message {
    display: block;
    color: #8D63FF;
    padding: 1rem;
}

/* #container {
    position: fixed;
    left: 50%;
    transform: translate(-50%, -0%);
    width: 200px;
    height: 0px;
} */

.no-scroll::-webkit-scrollbar {
    display: none;
}
.no-scroll {
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.swiper-pagination-bullet-active {
    background-color: #8D63FF !important;
}